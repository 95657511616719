:root {
   - --primary-color: #303d5f;
    --secondary-color: #F53359;
    --border-radius-primary: 8px;
    --text-color: #1a284c;
    --progress-color: var(--primary-color);

    --secondary-100: #FFF5F7;
    --secondary-200: #FCC5D0;
    --secondary-300: #FA94A8;
    --secondary-400: #F86381;
    --secondary-500: #F53359;
    --secondary-600: #EA0B38;
    --secondary-700: #B9092C;
    --secondary-800: #880620;
    --secondary-900: #580415;

    --primary-100: #F8F9FC;
    --primary-200: #D6DCEB;
    --primary-300: #B4BFDA;
    --primary-400: #92A2C9;
    --primary-500: #7085B8;
    --primary-600: #5269A3;
    --primary-700: #415381;
    --primary-800: #303D5F;
    --primary-900: #1F273D;
  }
  @primary-color: var(--primary-color);
  @secondary-color: var(--secondary-color);
  @border-radius-primary: var(--border-radius-primary);
  @text-color: var(--text-color);
  @progress-color: var(--progress-color);

  @primary-100: var(--primary-100);
  @primary-200: var(--primary-200);
  @primary-300: var(--primary-300);
  @primary-400: var(--primary-400);
  @primary-500: var(--primary-500);
  @primary-600: var(--primary-600);
  @primary-700: var(--primary-700);
  @primary-800: var(--primary-800);
  @primary-900: var(--primary-900);

  @secondary-100: var(--secondary-100);
  @secondary-200: var(--secondary-200);
  @secondary-300: var(--secondary-300);
  @secondary-400: var(--secondary-400);
  @secondary-500: var(--secondary-500);
  @secondary-600: var(--secondary-600);
  @secondary-700: var(--secondary-700);
  @secondary-800: var(--secondary-800);
  /* color base */
  @secondary-900: var(--secondary-900);