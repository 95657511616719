:root {
    /*--primary-color: #303d5f;
    --secondary-color: #f53359;*/
    --border-radius-primary: 8px;
    --text-color: #1a284c;
    --progress-color: var(--primary-color);

    --primary-100: #FFF5F7;
    --primary-200: #FCC5D0;
    --primary-300: #FA94A8;
    --primary-400: #F86381;
    --primary-500: #F53359;
    --primary-600: #EA0B38;
    --primary-700: #B9092C;
    --primary-800: #880620;
    --primary-900: #580415;

    --secondary-100: #F8F9FC;
    --secondary-200: #D6DCEB;
    --secondary-300: #B4BFDA;
    --secondary-400: #92A2C9;
    --secondary-500: #7085B8;
    --secondary-600: #5269A3;
    --secondary-700: #415381;
    --secondary-800: #303D5F;
    /* color base */
    --secondary-900: #1F273D;

}