@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(./styles/_variables.less);

@-webkit-keyframes pulse {
  50% {
    background: white;
  }
}
@keyframes pulse {
  50% {
    background: white;
  }
}

#preloader {
  position: fixed;
  background-color: #2f3537;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .spinner {
    align-self: flex-end;
    margin-bottom: 15px;
    content: '';
    position: relative;

    //top: 90%;
    //left: 50%;

    width: 6px;
    height: 24px;
    //margin: -3px 0 0 -12px;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-animation: pulse 750ms infinite;
    animation: pulse 750ms infinite;
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;

    &:before, &:after {
      content: '';
      position: absolute;
      display: block;
      height: 16px;
      width: 6px;
      background: rgba(255, 255, 255, 0.2);
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-animation: pulse 750ms infinite;
      animation: pulse 750ms infinite;
    }

    &:before {
      left: -12px;
    }

    &:after {
      left: 12px;
      -webkit-animation-delay: 500ms;
      animation-delay: 500ms;
    }

  }
}


.answer_success {
  border: 1px solid #31da8c !important;
}

.answer_failed {
  border: 1px solid red !important;
}

select {
  background-image: none;
}

.sentence {
  padding: 0.5rem;
}

.course-final .score.course-score {
  width: 33% ;
}

.modal__media .modal-content {
    text-align: center;
    background: none !important;
    margin: 5rem 0 0 0;
}

.m-link {
    cursor: pointer;
}

.m-link--white {
    color: white !important;
}

.m-link--blue {
    color: #5b70ff !important;
}

.media-holder {
    background-color: #FFF;
}

.multiline {
  .fr-img-caption {
    &.fr-dib {
      margin: 5px auto;
      display: block;
      float: none;
      vertical-align: top;
    }

    &.fr-fil {
      margin-left: 0;
      text-align: left;
    }

    &.fr-fir {
      margin-right: 0;
      text-align: right;
    }

    .fr-img-wrap {
      padding: 0;
      display: inline-block;
      margin: auto;
      text-align: center;
      width: 100%;

      img {
        display: block;
        margin: auto;
        width: 100%;
      }

      .fr-inner {
        margin: auto;
        display: block;
        padding: 5px 5px 10px;
        font-size: 14px;
        font-weight: initial;
        box-sizing: border-box;
        opacity: 0.9;
        width: 100%;
        text-align: center;
      }
    }
  }
}

#chatbase-bubble-button {
    display: none !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 2px;
}

#chatbase-message-bubbles {
    display: none !important;
}
